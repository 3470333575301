import React from "react";
import NoRoute from "../../Assets/Images/3828537.jpg";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import { Button } from "@mui/material";

const NoRoutes = ({ title, handleReset }) => {
  const handleGo = () => {
    handleReset();
  };
  return (
    <div className="grid-sidebar-row">
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        {/* container for screen starts here */}
        <div className="wrapper-container" style={{ padding: 0 }}>
          <div
            className="wrapper-container-fluid"
            style={{ marginTop: "0rem" }}
          >
            <div className="no-routes">
              <img src={NoRoute} alt="" />
              {title && <p>{title}</p>}
            </div>
            <Button onClick={handleGo}>Refresh</Button>
          </div>
        </div>
        {/* container for screen ends here */}
      </div>
    </div>
  );
};

export default NoRoutes;
