import React from "react";
import "./Header.css";
import { Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  let auth_user = JSON.parse(secureLocalStorage.getItem("user_meet"));
  let user_name = auth_user?.firstname + "" + auth_user?.lastname;

  console.log("USER NAME: ", user_name);

  const handleLogout = () => {
    // clickedTone();
    secureLocalStorage.removeItem("user_meet");
    navigate("/login");
  };

  // const handleSearch = () => {};

  return (
    <div className="header-wrapper">
      {/* grid 2 col starts here */}
      <div className="header-grid-row">
        <div>
          {/* <div className="search-area">
            <input
              type="text"
              //   value={keyword}
              //   onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search by agenda, descriptions, meetings points"
            />
            <Button onClick={handleSearch} className="search-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 17L21 21M3 11C3 13.1217 3.84285 15.1566 5.34315 16.6569C6.84344 18.1571 8.87827 19 11 19C13.1217 19 15.1566 18.1571 16.6569 16.6569C18.1571 15.1566 19 13.1217 19 11C19 8.87827 18.1571 6.84344 16.6569 5.34315C15.1566 3.84285 13.1217 3 11 3C8.87827 3 6.84344 3.84285 5.34315 5.34315C3.84285 6.84344 3 8.87827 3 11Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div> */}
        </div>
        <div>
          {/* <div className="count-aler-c">
            <div>
              <p>{count && count?.count ? count?.count : 0}</p>
            </div>
            <NotificationsIcon />
          </div> */}
          <div className="avatar-flex-row">
            <div>
              {" "}
              {user_name?.slice(0, 1)}
              {user_name.split(" ")[1]?.charAt(0)}
            </div>
            <div>
              <p>{user_name}</p>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          </div>
        </div>
      </div>
      {/* grid 2 col ends here */}
    </div>
  );
};

export default Header;
